<template>
  <section class="section transaction">

    <div class="container">
      <el-row>
        <el-col :span="24" :lg="24" class="mt-10">
          <el-row v-if="huadon.lastWithdrawList == undefined && huadon.lastDepositList==undefined"
                  v-loading="huadon.lastWithdrawList == undefined && huadon.lastDepositList == undefined"
                  element-loading-background="rgba(0, 0, 0, 0)">
          </el-row>
          <el-row v-else type="flex" style="flex-wrap: wrap;" justify="space-around">
            <el-col :xs="24" :span="10" class="">
              <div class="col-item">
                <div class="item-head">
                  <span >Last Deposit</span>
                </div>
                <article class="item-body ">
                  <vueSeamlessScroll :data="huadon.lastDepositList" :class-option="options" class="seamless-warp">
                    <div>
                      <div class="card1" v-for="(it,i) in huadon.lastDepositList" :key="i">
                        <div class="d-flex align-items-center justify-content-between" style="padding-top:2px;padding-bottom: 2px;" >
                          <div class="card__left1 d-flex align-items-center">
                            <!--                    <div class="activity__product--card__thumbnail1">-->
                            <!--                      <img src="/assets/flags/4x3/br.svg" style="width:60%;">-->
                            <!--                    </div>-->
                            <div class="card__content">
                              <div class="card__title" >{{it.email}}</div>
                              <div class="card__subtitle" style="color:red; ">{{ it.amount.toFixed(3) }} {{it.coinType}}</div>
                              <span class="card__time">{{ it.updatedAt | parseTime('{m}/{d}/{y}') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </vueSeamlessScroll>
                </article>
              </div>
            </el-col>
            <el-col :xs="24" :span="10" class="">
              <div class="col-item">
                <div class="item-head">
                  <span >Last Withdraw</span>
                </div>
                <article class="item-body ">
                  <vueSeamlessScroll :data="huadon.lastWithdrawList" :class-option="options" class="seamless-warp">
                    <div>
                      <div class="card1" v-for="(it,i) in huadon.lastWithdrawList" :key="i">
                        <div class="d-flex align-items-center justify-content-between" style="padding-top:2px;padding-bottom: 2px;" >
                          <div class="card__left1 d-flex align-items-center">
                            <!--                    <div class="activity__product--card__thumbnail1">-->
                            <!--                      <img src="/assets/flags/4x3/br.svg" style="width:60%;">-->
                            <!--                    </div>-->
                            <div class="card__content">
                              <div class="card__title">{{it.email}}</div>
                              <div class="card__subtitle" style="color:red; ">{{ it.amount.toFixed(3) }} {{it.coinType}}</div>
                              <span class="card__time">{{ it.updatedAt | parseTime('{m}/{d}/{y}') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </vueSeamlessScroll>
                </article>

              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

  </section>
</template>

<script>
import indexApi from "@/apis/indexApi";
import vueSeamlessScroll from 'vue-seamless-scroll'
import date, {parseTime} from "@/date";
// import '@/style/common.scss'
export default {
  name: "Transactions",
  components:{vueSeamlessScroll},
  filters: {parseTime},
  data() {
    return {
      showLastDeposit: true,
      showLastWithdraw: false,
      options: {
        autoPlay: false,
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 88, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100000000000 // 单步运动停止的时间(默认值1000ms)
      },
      huadon: {
        lastDepositList: undefined,
        lastWithdrawList: undefined
      },
    }
  },
  created() {
    indexApi.liushui(res => {
      this.huadon = res.data
      if(this.huadon && (this.huadon.lastDepositList.length > 0 || this.huadon.lastDepositList.length > 0)){
        this.$nextTick(() => {
          let height = 0;
          const card1 = document.getElementsByClassName('card1')[0];
          if (card1) {
            height = card1.clientHeight;
          }
          this.options = {
            autoPlay: true,
            step: 1, // 数值越大速度滚动越快
            limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
            hoverStop: false, // 是否开启鼠标悬停stop
            direction: 1, // 0向下 1向上 2向左 3向右
            openWatch: true, // 开启数据实时监控刷新dom
            singleHeight: height, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
          }
        })
      }

    })
  },
  methods:{
    getDate(d) {
      return date.getDate(d)
    },

    showLastDepositData(){
      this.showLastDeposit = true;
      this.showLastWithdraw = false;
    },
    showLastWithdrawData(){
      this.showLastWithdraw = true;
      this.showLastDeposit = false;
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';
.seamless-warp{
  height: 100%;
  overflow: hidden;
}



.nav-pills .nav-link.active {
  background-color: #384247 !important;
}


.transaction {
  padding: 0px;
  margin-bottom: 20px;
  .container{
    padding: 0;
  }
  .left-box{
    //display: flex;
    //flex-wrap: wrap;
    .el-col{
      height: 100%;
    }
    .item{
      //border: 1px solid rgba(224, 230, 237, 1);
      border-style: solid;
      //border-color: rgba(255, 190, 61, 1);
      border-color: #55d6dd;
      display: flex;
      flex-direction: column;
      //width: 250px;
      //height: 185px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 16px;
      margin: 20px auto;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
      padding: 24px;
      img{
        width: 46px;
        height: 46px;
      }
      .p1{
        //width: 209px;
        //height: 23px;
        font-size: 20px;
        //font-family: PingFangSC-Regular, PingFang SC;
        color: #273444;
        line-height: 23px;
        margin-top: 24px;
      }
      .p2{
        //width: 209px;
        //height: 36px;
        font-size: 16px;
        //font-family: PingFangSC-Regular, PingFang SC;
        //color: #8492A6;
        color: #273444;
        line-height: 18px;
        margin-top: 8px;
      }
    }
  }


  .card1{
    //height: 431px;
    //background: #FFFFFF;
    //box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.12);
    //border-radius: 0px 0px 6px 6px;
  }
  .col-item{
    .item-head{
      width: 120px;
      height: 47px;
      background: #FFFFFF;
      box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.04);
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        //width: 85px;
        height: 20px;
        font-size: 14px;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 800;
        //color: #0C66FF;
        //color: #55d6dd;
        color: #0a0a1a;
        line-height: 20px;
        position: relative;
        &::after{
          content: '';
          //width: 84px;
          height: 2px;
          //background: #0C66FF;
          background: #55d6dd;
          position: absolute;
          left: 1px;
          right: 1px;
          //transform: translateX(-50%);
          bottom: -4px;
        }
      }
    }
    .item-body{
      //width: 223px;
      height: 431px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.12);
      border-radius: 0px 0px 6px 6px;

      .card1{
        border-bottom: 1px solid #e4e4e4;
        padding: 7px 13px;
        .card__left1{
          gap: 22px;
        }
        .card__content{
          .card__title{
            //width: 155px;
            height: 22px;
            font-size: 16px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #273444;
            line-height: 22px;
            text-shadow: 0px 2px 8px rgba(0,0,0,0.12);
          }
          .card__subtitle{
            height: 20px;
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF0000;
            line-height: 20px;
            text-shadow: 0px 2px 8px rgba(0,0,0,0.12);
            margin-top: 2px;
          }
          .card__time{
            height: 20px;
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #273444;
            line-height: 20px;
            text-shadow: 0px 2px 8px rgba(0,0,0,0.12);
            margin-top: 2px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .col-item{
      .item-head{
        margin-top: 50px;
      }
    }

    .left-box{
      .item{
        margin:5px;
      }
      .el-col{
        width: 100% !important;
      }
    }
  }

  //@media only screen and (max-width: 800px) {
  //  .col-item{
  //    .item-head{
  //      margin-top: 50px;
  //    }
  //  }
  //}
}
</style>
