<template>
  <div class="scroll-text" v-show="show">
    <div class="wrapper" ref="wrapper">
      <div class="inner" ref="inner">
        <img style="width: 22px;" src="@/assets/icon/fire.svg" alt="fire"/>
        <img style="width: 22px;" src="@/assets/icon/fire.svg" alt="fire"/>
        In order to give back to investors, a profit-sharing promotion was launched in the middle of the year: recharge $2,000) and get an iPhone 14 Pro (worth $1,000, only for the top 100).🚀🚀
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'marqueeNew',
  props: {
    duration: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      offset: 0,
      show: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.show = true;
      setTimeout(() => {
        this.startAnimation();
      }, 1);
    });
  },
  methods: {
    preventDefault(event) {
      event.preventDefault();
    },
    startAnimation() {
      const wrapperWidth = this.$refs.wrapper.offsetWidth;
      const innerWidth = this.$refs.inner.offsetWidth;

      if (innerWidth > wrapperWidth) {
        const animationDuration = (innerWidth + wrapperWidth) / 100 * this.duration;
        this.animate(animationDuration, wrapperWidth, innerWidth);
      }
    },
    animate(animationDuration, wrapperWidth, innerWidth) {
      this.offset = 0;
      setTimeout(() => {
        this.offset = -(innerWidth - wrapperWidth);
        setTimeout(() => {
          this.animate(animationDuration, wrapperWidth, innerWidth);
        }, 50);
      }, animationDuration);
    },
  },
};
</script>

<style>
.scroll-text {
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.scroll-text .wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top:5px;
  overflow: hidden;
}

.scroll-text .inner {
  display: inline-block;
  white-space: nowrap;
  transform: translateX(100%);
  animation: marquee 20s cubic-bezier(0, 0, 0.5, 1) 1s infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
